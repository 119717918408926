import { css } from 'styled-components';

export const colors = {
   white: '#ffffff',
   black: '#000000',
   blue: '#295BFC',
   green: '#00AC8E',
   red: '#FB334B',
   lightBlue: '#D8EAFA',
   lightGreen: '#E2F8FF',
   lightViolet: '#F0F4FF',
   trialBg: '#D9F6FF',

   greenMap: new Map([
      [100, '#00AC8E'],
      [200, '#12C6A4'],
      [300, '#65D585'],
      [400, '#83E39E'],
   ]),

   greyMap: new Map([
      [100, '#A09FB4'],
      [200, '#A7A7A7'],
      [300, '#D8D8D8'],
      [400, '#E3E3E9'],
   ]),

   darkBlueMap: new Map([
      [100, '#19164A'],
      [200, '#211E4E'],
      [300, '#221F51'],
      [400, '#2E2B60'],
   ]),

   lightCyanMap: new Map([
      [100, '#BEEFEA'],
      [200, '#D9F6FF'],
      [300, '#DCF7FF'],
      [400, '#E4EFFF'],
      [500, '#E7FBFB'],
   ])
};
colors.brand = colors.green;

export const fonts = {
   primary: "'Metropolis', sans-serif",
};

export const transitions = {
   easeInOut: '.15s ease-in-out',
   easeInOutSlow: '.5s cubic-bezier(0.5, 0, 0.5, 1)',
   ease: '.5s ease',
};

export const breakpointsList = {
   xs: '375px',
   sm: '576px',
   md: '768px',
   lg: '992px',
   xl: '1200px',
};

export const breakpoints = Object.keys(breakpointsList).reduce(
   (accumulator, label) => {
      accumulator[label] = (...args) => css`
         @media (min-width: ${breakpointsList[label]}) {
            ${css(...args)};
         }
      `;
      return accumulator;
   },
   {},
);

const theme = {
   colors: colors,
   transitions: transitions,
   fonts: fonts,
};

export default theme;
