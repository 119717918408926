// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-funkcjonalnosci-js": () => import("./../../../src/pages/funkcjonalnosci.js" /* webpackChunkName: "component---src-pages-funkcjonalnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inteligentny-rozwoj-js": () => import("./../../../src/pages/inteligentny-rozwoj.js" /* webpackChunkName: "component---src-pages-inteligentny-rozwoj-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-korzysci-js": () => import("./../../../src/pages/korzysci.js" /* webpackChunkName: "component---src-pages-korzysci-js" */),
  "component---src-pages-potwierdzenie-js": () => import("./../../../src/pages/potwierdzenie.js" /* webpackChunkName: "component---src-pages-potwierdzenie-js" */)
}

