import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { globalHistory as history } from '@reach/router';
import logo from 'assets/logo_color.svg';
import eulogo from 'assets/images/eulogo.png';
import Button from 'components/shared/Button';
import { useNavbarContext } from 'contexts/NavbarContext';
import routes from 'data/globals/routes';
import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import scrollTo from 'utilities/scrollTo';
import Hamburger from './Hamburger';
import {
   DarkOverlay,
   ListContainer,
   ListItemWithList,
   MenuContainer,
   NavContainer,
   NavList,
   NavWrapper,
} from './Navbar.styled';

const Navbar = () => {
   const [solutionsVisible, setSolutionsVisible] = useState(false);
   const [functionalitiesVisible, setFunctionalitiesVisible] = useState(false);
   const [isMenuOpen, setMenuOpen] = useState(false);
   const { location } = history;
   const { isMenuVisible, hideMenu, showMenu } = useNavbarContext();
   const navRef = useRef(null);
   const isFirstRender = useRef(true);
   const closeMenu = () => setMenuOpen(false);

   let prevShouldNavbarHide = null;

   useScrollPosition(
      ({ prevPos, currPos }) => {
         if (prevPos.y === currPos.y) return;

         const prevY = Math.abs(prevPos.y);
         const currY = Math.abs(currPos.y);
         const navHeight = navRef.current.getBoundingClientRect().height;
         const shouldNavbarHide = currY > prevY && currY > navHeight;

         if (currY < navHeight) navRef.current.classList.remove('scroll-up');

         if (prevShouldNavbarHide === shouldNavbarHide) return;

         shouldNavbarHide ? hideMenu(-navHeight) : showMenu(navHeight, 0);

         prevShouldNavbarHide = shouldNavbarHide;
      },
      [],
      null,
      false,
      100,
   );

   useEffect(() => {
      if (isFirstRender.current) {
         isFirstRender.current = false;
         return;
      }

      const nav = navRef.current;
      if (isMenuVisible) {
         nav.classList.remove('scroll-down');
         nav.classList.add('scroll-up');
      } else {
         nav.classList.remove('scroll-up');
         nav.classList.add('scroll-down');
      }
   }, [isMenuVisible]);

   useEffect(() => {
      const overflowStatus = isMenuOpen ? 'hidden' : 'visible';
      document.body.style.overflow = overflowStatus;
   }, [isMenuOpen]);

   const handleClickLink = (sectionId) => {
      location.pathname === routes.functionalities && scrollTo(sectionId);
      closeMenu();
   };

   return (
      <NavContainer fullwidth ref={navRef}>
         <NavWrapper as="nav">
            <Link to={routes.home}>
               <img alt="logo" src={logo} height="30px" />
            </Link>
            <Hamburger isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
            {isMenuOpen && (
               <DarkOverlay onClick={closeMenu} isMenuOpen={isMenuOpen} />
            )}
            <MenuContainer isMenuOpen={isMenuOpen}>
               <ListContainer>
                  <NavList>
                     <li>
                        <Link
                           to={routes.benefits}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Korzyści
                        </Link>
                     </li>
                     <li>
                        <Link
                           to={routes.functionalities}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Funkcjonalności
                        </Link>
                     </li>
                     <li>
                        <Link
                           to={routes.pricing}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Cennik
                        </Link>
                     </li>
                     <li>
                        <Link
                           to={routes.contact}
                           onClick={closeMenu}
                           activeClassName="active-nav-link"
                        >
                           Kontakt
                        </Link>
                     </li>
                     <li className="cta">
                        <Button
                           small
                           filled
                           link='#'
                           onClick={closeMenu}
                        >
                           Wypróbuj
                        </Button>
                     </li>
                     <li>
                        <Link
                           to={routes.eu}
                           onClick={closeMenu}
                           className="euLogoLink"
                           activeClassName="active-nav-link"
                        >
                           <img alt="eulogo" src={eulogo} className="euLogo" height="45px" />
                        </Link>
                     </li>
                  </NavList>
               </ListContainer>
            </MenuContainer>
         </NavWrapper>
      </NavContainer>
   );
};
export default Navbar;
