import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, transitions } from 'variables';

const buttonStyles = css`
   font-family: ${fonts.primary};
   font-weight: 500;
   border: 1px solid ${colors.brand};
   text-align: center;
   cursor: pointer;
   font-size: 1.5rem;
   padding: 10px 20px;
   

   transition: background ${transitions.easeInOut},
      color ${transitions.easeInOut};

   color: ${({ $filled }) => ($filled ? colors.white : colors.black)};
   background: ${({ $filled }) => ($filled ? colors.brand : colors.white)};
   line-height: ${({ $small }) => ($small ? '18px' : '16px')};
   color: ${({ $filled }) => ($filled ? colors.white : colors.black)};
   background: ${({ $filled }) => ($filled ? colors.brand : colors.white)};
   line-height: ${({ $small }) => ($small ? '18px' : '16px')};

   &:hover {
      background: ${({ $filled }) => ($filled ? 'transparent' : colors.brand)};
      color: ${({ $filled }) => ($filled ? colors.black : colors.white)};
   }

   ${breakpoints.lg`
      font-size: ${({ $small }) => ($small ? '1.5rem' : '1.7rem')};
      padding: ${({ $small }) => ($small ? '10px 30px' : '20px 35px 19px')};
      ${({ link }) => (link ? 'padding: 0' : null)};
   `}
`;

const StyledButton = styled.button`
   ${buttonStyles}
`;

export const StyledButtonLink = styled(Link)`
   ${buttonStyles};
   display: inline-block;
`;

export default StyledButton;
