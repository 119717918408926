import facebook from 'assets/icons/socials/facebook_24.svg';
import linkedin from 'assets/icons/socials/linkedin_24.svg';
import youtube from 'assets/icons/socials/youtube_24.svg';
import logo from 'assets/logo_color.svg';
import poirlogo from 'assets/images/poir.png';
import privacy from 'assets/privacy.pdf';
import Button from 'components/shared/Button';
import Container from 'components/shared/Container';
import TrialSection from 'components/shared/TrialSection';
import { useNavbarContext } from 'contexts/NavbarContext';
import routes from 'data/globals/routes';
import { Link } from 'gatsby';
import useOnClickOutside from 'hooks/useClickOutside';
import React, { useRef, useState } from 'react';
import {
   BigSectionHeader,
   Contact,
   CreatorsContainer,
   CreditsContainer,
   FooterListWrapper,
   List,
   Logo,
   LogoContainer,
   SectionHeader,
   StyledFooter,
} from './Footer.styled';
import StockTooltip from './StocksTooltip';

const Footer = () => {
   const [isStocksTooltipOpen, setIsStocksTooltipOpen] = useState(false);
   const stockTooltipRef = useRef();
   useOnClickOutside(stockTooltipRef, () => setIsStocksTooltipOpen(false));

   const { goToId } = useNavbarContext();
   return (
      <>
         <TrialSection />
         <Container>
            <StyledFooter>
               <Contact>
                  <BigSectionHeader>Masz pytania?</BigSectionHeader>
                  <Button link={routes.contact}>Skontaktuj się</Button>
               </Contact>
               <FooterListWrapper>
                  <SectionHeader>O produkcie</SectionHeader>
                  <List>
                     <li>
                        <Link to={routes.benefits}>Korzyści</Link>
                     </li>
                     <li>
                        <Link to={routes.functionalities}>Funkcjonalności</Link>
                     </li>
                     <li>
                        <Link to={routes.pricing}>Cennik</Link>
                     </li>
                  </List>
               </FooterListWrapper>

               <FooterListWrapper>
                  <SectionHeader>O nas</SectionHeader>
                  <List>
                     <li>
                        <Link to={routes.contact}>Kontakt</Link>
                     </li>
                     <li>
                        <Link to={routes.pricing} onClick={() => goToId('faq')}>
                           Pomoc i FAQ
                        </Link>
                     </li>
                     <li>
                        <a
                           href={privacy}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Polityka Prywatności
                        </a>
                     </li>
                  </List>
               </FooterListWrapper>

               <div>
                  <img alt="poirlogo" src={poirlogo} className="poirLogo" />
                  <div className="poirDescription">Proces Control Sp. z o.o. bierze udział w projekcie "Inteligentny Rozwój". <Link to={routes.eu}>Dowiedz się więcej</Link></div>
               </div>

               <hr />

               <CreditsContainer>
                  <LogoContainer>
                     <Link to={routes.home}>
                        <Logo src={logo} alt="logo" />
                     </Link>
                  </LogoContainer>

                  <CreatorsContainer>
                     <button
                        onClick={() => setIsStocksTooltipOpen((prev) => !prev)}
                        className="greyLink"
                     >
                        Images <strong>&nbsp;Freepik&nbsp;</strong>
                        {isStocksTooltipOpen && (
                           <StockTooltip ref={stockTooltipRef} />
                        )}
                     </button>
                  </CreatorsContainer>
               </CreditsContainer>
            </StyledFooter>
         </Container>
      </>
   );
};

export default Footer;
