export default {
   home: '/',
   functionalities: '/funkcjonalnosci',
   benefits: '/korzysci',
   pricing: '/cennik',
   contact: '/kontakt',
   privacy: '/prywatnosc',
   eu: '/inteligentny-rozwoj',
   confirmation: '/potwierdzenie',
};
