import Container from 'components/shared/Container';
import styled from 'styled-components';
import { breakpoints, colors, transitions } from 'variables';

export const NavContainer = styled(Container)`
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   padding: 30px 0 10px;
   font-size: 1.6rem;
   background-color: ${colors.white};
   transition: top ${transitions.ease}, padding ${transitions.ease};
   border-bottom: 1px solid white;
   z-index: 1;
   display: flex;

   .euLogoLink {
      padding: 0;
   }
   .euLogo {
     margin-top: 7px;
   }

   &.scroll-up {
      padding-top: 10px;
      border-bottom: 1px solid ${colors.greyMap.get(400)};
      top: 0;
   }

   &.scroll-down {
      padding-top: 10px;
      top: -61px;
   }
`;

export const NavWrapper = styled(Container)`
   justify-content: space-between;
   align-items: center;

   img {
      max-width: 210px;
   }
`;

export const MenuContainer = styled.div`
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100vh;
   display: flex;

   transform: translateX(${({ isMenuOpen }) => (isMenuOpen ? '0%' : '100%')});
   transition: transform ${transitions.ease};

   ${breakpoints.lg`
      width: auto;
      height: auto;
      position: relative;
      transform: none;
   `}
`;

export const ListContainer = styled.div`
   flex-grow: 1;
   height: 100%;
   background-color: ${colors.white};
   padding: 30px;

   .cta {
      a {
         padding-left: 20px;
         padding-right: 20px;
      }
   }

   li {
      font-size: 2.2rem;
      font-weight: 300;
      cursor: pointer;
      button {
         font-family: inherit;
         font-weight: inherit;
         background: none;
         border: none;
      }

      a,
      button {
         cursor: pointer;
         padding: 10px 0;
         display: block;

         &:hover {
            color: ${colors.brand};
         }
      }
   }

   ${breakpoints.lg`
         li {
            font-size: 1.5rem;
            font-weight: 500;

            a, button {
               padding-left: 12px;
               padding-right: 12px;
            }
         }

      }

      padding: 0;
   `}
`;

export const NavList = styled.ul`
   margin-top: 90px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 30px;

   ${breakpoints.lg`
      margin: 0;
      flex-direction: row;
      align-items: center;
   `}
`;

export const ListItemWithList = styled.li`
   padding: 0;
   position: relative;

   .with-arrow {
      &:after {
         position: absolute;
         content: '';
         width: 7px;
         height: 7px;
         border-right: 1px solid ${colors.brand};
         border-bottom: 1px solid ${colors.brand};
         transform: ${(props) =>
            !props.arrowUp
               ? 'rotate(45deg) translateY(-35%)'
               : 'rotate(-45deg) translateY(-35%)'};
         transition: border-color ${transitions.easeInOut};
         margin-left: 10px;
         margin-top: 5px;
         ${breakpoints.lg`
            border-right: 1px solid ${colors.black};
            border-bottom: 1px solid ${colors.black};
            margin-top: 4px;
            margin-left: 6px;
         `}
      }
   }

   .nested-list {
      display: block;
      padding: 5px 15px;
      a {
         font-size: 1.6rem;
      }
   }
   .invisible-mobile {
      display: none;
   }

   ${breakpoints.lg`
      .with-arrow {
         &:after {
            transform: rotate(45deg) translateY(-35%)
         }
      }
      .nested-list {
         display: none;
         padding: 10px;

      }

      display: flex;
      align-items: center;
      margin-right: 20px;


      &:hover {

         > a {
            color: ${colors.brand};
         }

         &:after {
            border-color: ${colors.brand};
         }

         .nested-list {
            display: flex;
            flex-direction: column;
            background-color: ${colors.white};
            width: auto;
            position: absolute;
            white-space: nowrap;
            top: 35px;
            left: 0;
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
         }
      }
   `}
`;

export const DarkOverlay = styled.div`
   flex-grow: 1;
   background-color: ${colors.black};
   opacity: 0.7;
   height: 100%;
   cursor: pointer;

   ${breakpoints.lg`
      display: none;
   `}
`;
