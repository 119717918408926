import { css } from 'styled-components';
import { colors, breakpoints } from 'variables';

import manropeRegular from 'assets/fonts/Manrope-Regular.ttf';
import manropeBold from 'assets/fonts/Manrope-Bold.ttf';
import manropeMedium from 'assets/fonts/Manrope-Medium.ttf';
import manropeExtraBold from 'assets/fonts/Manrope-ExtraBold.ttf';
import manropeSemiBold from 'assets/fonts/Manrope-SemiBold.ttf';
import manropeLight from 'assets/fonts/Manrope-Light.ttf';
import manropeExtraLight from 'assets/fonts/Manrope-ExtraLight.ttf';

const typography = css`
   * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }

   @font-face {
      font-family: 'Manrope';
      src: local('Manrope Regular'), local('Manrope-Regular'),
         url(${manropeRegular}) format('ttf');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope';
      src: local('Manrope Bold'), local('Manrope-Bold'),
         url(${manropeBold}) format('ttf');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope';
      src: local('Manrope Medium'), local('Manrope-Medium'),
         url(${manropeMedium}) format('ttf');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope Extra';
      src: local('Manrope Extra Bold'), local('Manrope-ExtraBold'),
         url(${manropeExtraBold}) format('ttf');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope Semi';
      src: local('Manrope Semi Bold'), local('Manrope-SemiBold'),
         url(${manropeSemiBold}) format('ttf');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope';
      src: local('Manrope Light'), local('Manrope-Light'),
         url(${manropeLight}) format('ttf');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
   }

   @font-face {
      font-family: 'Manrope';
      src: local('Manrope ExtraLight'), local('Manrope-ExtraLight'),
         url(${manropeExtraLight}) format('ttf');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      margin: 0;
      font-weight: 300;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   a,
   p,
   span,
   figcaption {
      color: ${colors.darkBlueMap.get(200)};
   }

   b,
   strong {
      font-weight: 700;
   }

   h1 {
      font-size: 2.6rem;
   }
   h2 {
      font-size: 2.4rem;
   }
   h3,
   h4 {
      font-size: 2rem;
   }
   h5 {
      font-size: 1.8rem;
   }
   p {
      font-size: 1.5rem;
      line-height: 2.4rem;

      small {
         font-size: 1.4rem;
      }
   }

   ${breakpoints.md`
      h1 { font-size: 4.2rem; }
      h2 { font-size: 3.4rem; }
      h3 { font-size: 2.5rem; }
      h4 { font-size: 2.1rem; }
      p {
         font-size: 1.6rem;

         small { font-size: 1.4rem; }
      }
   `}

   ${breakpoints.lg`
      h1 { font-size: 5.8rem; }
      h2 { font-size: 4.5rem; }
      h3 { font-size: 3rem; }
      h4 { font-size: 2.2rem; }
      p {
         line-height: 2.6rem;
         font-size: 1.6rem;

         small { font-size: 1.4rem; }
      }
   `}
`;

export default typography;
