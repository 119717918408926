import React from 'react';
import Container from 'components/shared/Container';
import Button from 'components/shared/Button';
import {
   TrialContainer,
   InformationContainer,
   Image,
   TrialHeader,
} from './TrialSection.styled';

import image from 'assets/images/photo9.png';
import imageX2 from 'assets/images/photo9@2x.png';
import imageMobile from 'assets/images/photo9.png';
import imageMobileX2 from 'assets/images/photo9@2x.png';
import routes from 'data/globals/routes';
import { breakpointsList } from 'styles/variables';

const TrialSection = () => {
   return (
      <Container>
         <TrialContainer>
            <InformationContainer>
               <TrialHeader>
                  <strong>Wypróbuj Proces Control</strong> teraz bez zobowiązań
               </TrialHeader>
               <Button filled link={routes.contact}>
                  Wypróbuj za darmo
               </Button>
            </InformationContainer>
            <Image>
               <source
                  media={`(max-width: ${breakpointsList.md})`}
                  srcSet={`${imageMobile} 1x, ${imageMobileX2} 2x`}
               />
               <source
                  media={`(min-width: ${breakpointsList.md})`}
                  srcSet={`${image} 1x, ${imageX2} 2x`}
               />
               <img src={image} alt="Man in a protective coat" />
            </Image>
         </TrialContainer>
      </Container>
   );
};

export default TrialSection;
